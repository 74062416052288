import './About.css';
import Logs from './Logs'

const About =()=> {
    return (
        <div className='text-con'>
            <div>
                Автор сайта:  
                <a target="_blank" rel="noreferrer" href="https://vk.com/alex_sin_cube"> Alex Sin (Басист)</a>
            </div>
            <br></br>
            <div>
                Если хотите поддержать проект:  
            </div>
            <div className='req'>
                <div>
                    Яндекс кошелек: 410012149694658    
                </div>
                <div>
                    Карта: 5599 0050 8661 9900    
                </div>
            </div>
            <br></br>
            <div>
                Этот сайт создан с цельбью облегчить поиски чертежей для быстрого создания строений
            </div>
            <div>
                Сайт еще будет дополняться различными чертежами
            </div>
            <div>
                Если вы обнаружили ошибку в рассчетах - то свяжитесь с автором и расскажите где замечены расхождения
            </div>
            <div>
                Если у вас есть чертежи для выставления на сайт, то свяжитесь с автором 
            </div>
            <br></br>
            <div>
                Все данные полученные на сайте являются примерными
            </div>
            <div>
                Все размеры указаны в мм
            </div>
            <div>
                В качестве основного крепежза рекомендуется использовать саморезы по дереву
            </div>
            <Logs />
        </div>
    )
}
export default About