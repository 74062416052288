import './Logs.css';

const Logs =()=> {
    return (
        <div className='text-con'>
            <h4>
               27.02.2021
            </h4>
            <div>
               Добавлен элемент:  Мебель / "Полки 750*300*1500"
            </div>
            <h4>
               26.02.2021
            </h4>
            <div>
               Исправлены валидации для поля ввода стоимости куба леса
            </div>
            <h4>
               23.02.2021
            </h4>
            <div>
               Исправлены дефекты верстки
            </div>
            <div>
               Добавлен элемент:  Мебель / "Кровать 2000*1000*500"
            </div>
            <div>
               Добавлен элемент:  Лагерь / "Лестница 3000*500"
            </div>
            <h4>
               20.02.2021
            </h4>
            <div>
                Добавлена верстка под ширину экрана: 1600,1366,1280,1024,800,412,360
            </div>
            <h4>
               19.02.2021
            </h4>
            <div>
                Испраавлена ошибка в рассчете экономии стола 1500*600*750
            </div>
            <h4>
               16.02.2021
            </h4>
            <div>
                Добавлен элемент:  Оборона / "Башня 5000*1500*2000"
            </div>
            <h4>
               15.02.2021
            </h4>
            <div>
                В Чек-лист добавлена схема полного подсчета количества необходимых досок
            </div>
            <h4>
               11.02.2021
            </h4>
            <div>
                Добавлен элемент:  Оборона / "Ворота 3000*2500*2000"
            </div>
            <h4>
               10.02.2021
            </h4>
            <div>
                Добавлен элемент:  Лагерь / "Мусорка 750*500*500"
            </div>
            <div>
               При выборе нового объекта из списка счетчик количества обнуляется
            </div>
            <div>
               Добавлена кнопка "Сброс" для обнуления данных в Чек листе
            </div>
            <div>
               Добавлена возможность составления списков (Чек-лист) для более успешного формирования бюджета
            </div>
            <br></br>
            <h4>
               08.02.2021
            </h4>
            <div>
               Исправление орфографических ошибок
            </div>
            <div>
                Добавлен элемент:  Лагерь / "Туалет 1000*750*2000"
            </div>
            <div>
                Добавлен элемент:  Лагерь / "Столб 750*750*2000"
            </div>
            <br></br>
            <h4>
               07.02.2021
            </h4>
            <div>
               Изменена внешность Калькулятора
            </div>
            <div>
               Исправлен оптимум объекта Стена 2000*2000*1000
            </div>
            <div>
            Добавлен элемент:  Лагерь / "Доспешная стойка 2000*1000*1000"
            </div>
            <div>
            Добавлен элемент:  Мебель / "Табурет 525*600*600"
            </div>
            <div>
            Страница "О сайте" стала заглавной
            </div>
            <div>
            Добавлены реквизиты для поддержки проекта
            </div>
            <div>
            Добавлены QR коды с картой распила
            </div>
            <h4>
               06.02.2021
            </h4>
            <div>
               Исправлены значение оптимальной закупки столов 2000*600*750
            </div>
            <div>
               Исправлены некоторые орфографические ошибки
            </div>
            <div>
               Добавлен элемент:  Лагерь / "Оружейная стойка 2000*2000*1000"
            </div>
        </div>
    )
}
export default Logs