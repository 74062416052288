
export const required = value => {
    if (value) return undefined
    return 'Field is required'
}
export const maxLengthCreator =(maxLength)=>(value)=> {
    if(value.length > maxLength) return `Максимальная длина числа: ${maxLength} цифр`
    return undefined
}
export const onlyNumbers =(value)=> {
    if (isNaN(value))  return 'isNaN'
    return undefined
}