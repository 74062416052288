import React from 'react'
import './WoodCalc.css'
import {useSelector,useDispatch} from 'react-redux'
import {updatePrice} from '../../actions'
import { Field, reduxForm } from 'redux-form'
import {required,maxLengthCreator, onlyNumbers } from '../../actions/validators.js'
import { InputCalc } from '../commons/FormsControls/FormsControls'


function WoodCalc(props) {
  let priceWood = useSelector(state =>state.counter.calc.sizePrice)
  let woodSizeReady = useSelector(state=>state.counter.calc.woodSize)
  let priceWoodElements = priceWood.map((el,index)=>
    <div className='woodRow'>
      <div className='woodPice'>{woodSizeReady[index]}</div> 
      <div className='woodPrice'>{el.toFixed(2)}</div></div>)
  
  const dispatch = useDispatch()

  const onUpdatePrice = (values) => {
    dispatch(updatePrice(values.newPrice))
  }
  
  return (
    <div className="calc-container">
      <AddWoodPriceRedux onSubmit={onUpdatePrice}/>
        <div className='row-container'>
          {priceWoodElements}
        </div>
    </div>
  );
}

let maxLength10 = maxLengthCreator(10)


const AddWoodPrice =(props)=> {
  return (
    <form onSubmit={props.handleSubmit} className="calc-container-ras">
    <h4>Введите стоимость куба леса</h4>
    <Field  className='input' component={InputCalc} placeHolder='Cтоимость куба леса' name={'newPrice'} 
    validate={[required,maxLength10,onlyNumbers]}
    />
    <button className='button'>Рассчитать</button>
      </form>
  )
}
const AddWoodPriceRedux = reduxForm({form:'calcAddNewPrice'})(AddWoodPrice)
export default WoodCalc;
