import counterReducer from './counterReducer'
import martReducer from './martReducer'
import {combineReducers} from 'redux'
import resultReducer from './resultReducer'
import {reducer as formReducer} from 'redux-form'


const rootReducer = combineReducers({
    counter:counterReducer,
    mart:martReducer,
    sum:resultReducer,
    form:formReducer

})

export default rootReducer
